*{
    box-sizing: border-box;
  }
  body{
    position: relative;
    margin: 0px;
    font-family: 'Manrope', "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
    /* font-family: 'Manrope', sans-serif; */
  }
  body.-fixed{
    overflow-y: hidden;
  }
  p{
    font-weight: 400;
    font-size: 14px;
  }
  h1,h2,h4,h6,p{
    margin: 0;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  li{
    list-style:none;
    font-size: 14px;
  }
  a {
    text-decoration: none;
  }
  input,textarea,select{
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
    box-sizing: border-box;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .test{
    position: fixed;
    bottom: 0;
    width: 300px;
    height: 100px;
    background-color: yellow;
    z-index: 999;
  }
  
  .datail-head{
    margin-bottom: 6px;
  }
  .listGroup{
    display: flex;
    justify-content: space-between;
    color: #5c5e62;
    padding: 2px 0;
  }
  .list-title{
    width: 200px;
  }
  .list-title.-adjust{
    padding-left: 42px;
  }
  .list-unit{
    text-align: left;
  }
  .listGroup p:last-child{
      margin-left: auto;
  }
  .modal-note{
    font-size: 12px;
    color: #5c5e62;
  }
  .header{
    position: fixed;
    height: 52px;
    width: 100%;
    z-index: 555;
    padding: 0 48px;
    backdrop-filter: blur(48px);
    background-color: rgba(255, 255, 255, 0.75);
  }
  .logo{
    width: 89px;
    height: 34px;
    margin-top: 18px;
    background-image: url("./images/logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .content{
    display: grid;
    grid-template-columns: 1fr 320px;
    column-gap: 48px;
    padding-right: 120px;
  }
  .image-wapper{
    position: sticky;
    top: 0;
    height: 100vh;
  }
  .image-block{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
  }
  .image-block.-on{
    animation: fadein-keyframes .5s ease 0s 1 forwards;
  }
  @keyframes fadein-keyframes{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .image-block img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: calc(100% - 192px);
  }
  .action-footerWrapper{
    position: fixed;
    bottom: 0;
    width: calc(100% - 488px);
    z-index: 999;
  }
  .action-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    margin: auto;
    background-color: #f4f4f4;
    border-radius: 20px 20px 0 0;
    padding: 26px 40px;
    cursor: pointer;
  }
  .footer-value{
    font-size: 14px;
  }
  .footer-value span{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .025em;
  }
  .footer-button{
    background-color: #fff;
    border-radius: 16px;
    padding: 5px 30px 3px;
    box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 rgb(0 0 0 / 10%);
  }
  .option-wapper{
    padding-top: 52px;
    padding-bottom: 240px;
  }
  .option-head{
    background-color: #f4f4f4;
    padding: 22px;
    border-radius: 8px;
  }
  .sp-image{
    display: none;
  }
  .option-head p{
    color: #5D5D5F;
    line-height: 19px;
  }
 
  .option-item h1.serviceName{
    font-size: 29px;
    line-height: 1.25;
    margin-top: 54px;
    margin-bottom: 51px;
    text-align: center;
  }
  .option-item ul.option-selectName{
    display: flex;
    justify-content: space-between;
    padding: 0 24px 0 15px;
    margin-bottom: 13px;
  }
  .option-item ul.option-selectName li{
    color: #5D5D5F;
  }
  .radio-input {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
  }
  .radio-label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 17px 24px 17px 15px;
    border-radius: 26px;
    border: 1px solid #A2A2A4;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .radio-input:checked + .radio-label {
    border: 2px solid #3e6ae1;
    padding: 16px 23px 16px 14px;
  }
  .option-item h2.itemName{
    font-size: 27px;
    font-weight: 400;
    line-height: 1.25;
    margin-bottom: 30px;
    text-align: center;
  }
  .input-wapper{
  }
  .text-input{
    font-size: 16px;
    font-family: 'Manrope', sans-serif;
    border-radius: 26px;
    color: #5D5D5F;
    background-color: #F4F4F4;
    border: 1px solid #F4F4F4;
    padding: 10px 15px 10px 15px;
    width: calc(100% - 56px);
    transition: border .25s cubic-bezier(.5,.25,.25,.75);
  }
  .text-input:focus{
    border: 1px solid #A2A2A4;
  }
  .inputUnit{
    display: inline-block;
    padding-left: 8px;
  }
  .itemNote p{
    text-align: justify;
    color: #5D5D5F;
    line-height: 19px;
    margin-top: 26px;
  }
  .itemDetail p{
    color: #5D5D5F;
    font-size: 16px;
    margin-top: 26px;
  }
  /*-------------------ipad Air2 mini4 1024×768----------------------*/
  @media screen and (max-width:1024px){
    .logo {
      margin-top: 9px;
    }
    .content {
      grid-template-columns: 1fr;
      padding-left: 0;
      padding-right: 0;
    }
    .image-wapper{
      position: fixed;
    }
    .image-block{
      display: none;
    }
    .option-head {
      max-width: 552px;
      margin: auto;
    }
    .sp-image{
      width: 100%;
      display: block;
      margin-bottom: 180px;
    }
    .sp-image.-top{
      margin-top: 60px;
      margin-bottom: 60px;
    }
    .option-item {
      max-width: 600px;
      margin-bottom: 180px;
      margin: 0 auto 180px;
      padding: 0 24px;
    }
    .serviceName br{
      display: none;
    }
    .action-footer {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      transform: translateX(0);
      background-color: #f4f4f4;
      border-radius: 20px 20px 0 0;
      padding: 26px 40px;
      cursor: default;
    }
  }
  /*-------------------ipad Air2 mini4 1024×768----------------------*/
  @media screen and (max-width:768px){
    .header {
      padding: 0 20px;
    }
    .modal{
      padding: 34px 38px 38px;
    }
    .option-wapper {
      padding-bottom: 140px;
    }
  }
  
  @media screen and (max-width:600px){
    .modal-main {
      display: block;
    }
    .modal-button {
      display: inline-block;
      margin-top: 10px;
    }
    .option-head{
      width: calc(100% - 48px);
      margin-left: 24px;
      margin-right: 24px;
    }
    .listGroup{
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: unset;
    }
    .list-title {
      width: auto;
      grid-column: 1 / 3;
    }
    .list-unit {
      padding-left: 14px;
    }
    .list-title.-adjust {
      padding-left: 5px;
    }
  }
  
  /*-------------------iphone6s plus 414×736----------------------*/
  @media screen and (max-width:414px){
    .serviceName br{
      display: block;
    }
  }
  /*-------------------iphone6s 375×667----------------------*/
  @media screen and (max-width:375px){}
  
  /*-------------------iphone5s 320×568----------------------*/
  @media screen and (max-width:320px){}
  
  /* edit */
  /* header */
  ._header{
    border-bottom: 1px solid #e0e0e0;
    padding: 22px 30px;
    box-sizing: border-box;
  }
  ._header ul{
    display: flex;
    justify-content: flex-start;
  }
  ._header ul a{
    margin: 0 24px 0 0;
    color: rgba(0, 0, 0, 0.87);
  }
  ._header ul li{
    font-size: 14px;
    font-weight: 400;
    font-size: 0.875rem;
  }
  ._header .userList{
    position: absolute;
    top: 18px;
    right: 30px;
    display: flex;
    align-items: center;
  }
  ._header .userList p.name{
    margin-right: 18px;
    color: #ce8585;
    font-weight: 600;
  }
  ._header .userList p.logout{
    color: #555;
    border: 1px solid #e0e0e0;
    padding: 5px 14px;
    font-size: 12px;
    border-radius: 20px;
    font-weight: 600;
  }
  
  .css-lp9mh7{
    padding-left: 40px!important;
    padding-right: 40px!important;
  }
  