.option-item {
    h2.itemName {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;
        margin-top: 1rem;
        margin-bottom: .2rem;
        text-align: left;
    }
}
.input-wapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .input-form {
        width: calc(100% - 5.6rem);
        flex-shrink: 0;
    }
    .inputUnit {
        padding-left: .8rem;
        flex-shrink: 0;
        width: 5.6rem;
        line-height: 3.2rem;
    }
    .itemNote p {
        text-align: justify;
        color: #5D5D5F;
        line-height: 19px;
        margin-top: 1rem;
    }
}