.modal-background{
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: hsla(0,0%,50.2%,.5);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 9999;
}
@keyframes fadeIn {
  0%{
    display: none;
    opacity: 0;
  }
  1%{
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
.modal-background.-available {
  display: block;
  opacity: 1;
  animation: fadeIn 0.3s ease 0s forwards;
}
.modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 800px;
  width: calc(100% - 48px);
  border-radius: 20px;
  padding: 34px 64px 94px;
  background-color: #fff;
  z-index: 99999;
  box-shadow: 0 0 40px 0 rgba(0,0,0,.5);
}
@keyframes fadeInUp {
  0%{
    display: none;
    opacity: 0;
    top: 53%;
  }
  1%{
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
    top: 50%;
  }
}
.modal.-available{
  display: block;
  opacity: 0;
  animation: fadeInUp 0.3s ease 0.1s forwards;
}
.modal-close{
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 16px;
  margin-right: 16px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  transition: .5s;
}
.modal-close:hover{
  background-color: #F4F4F4;
}
.modal-close::before{
  position: absolute;
  top: 6px;
  left: 15px;
  content: "";
  height: 21px;
  width: 2px;
  background-color: black;
  transform: rotate(45deg);
}
.modal-close::after{
  position: absolute;
  top: 6px;
  right: 15px;
  content: "";
  height: 21px;
  width: 2px;
  background-color: black;
  transform: rotate(-45deg);
}
.modal-head{
  font-size: 27px;
  margin-bottom: 20px;
}
.modal-main{
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d1d2;
  padding-bottom: 16px;
  overflow-y: auto;
  max-height: 560px;
}
.modal-mainTitle{
  font-size: 18px;
}
.modal-value{
  /* font-size: 14px; */
}
.modal-value span{
  font-size: 20px;
  font-weight: 600;
  letter-spacing: .025em;
}
.modal-button{
  background-color: #3e6ae1;
  color: #fff;
  border-radius: 16px;
  padding: 5px 28px;
  transition: .5s;
}
.modal-button:hover{
  background-color: #3A59AB;
  transition: .5s;
  cursor: pointer;
}
.modal-detail{
  margin-top: 20px;
  margin-bottom: 20px;
}
.datail-head{
  margin-bottom: 6px;
}
.listGroup{
  display: flex;
  justify-content: space-between;
  color: #5c5e62;
  padding: 2px 0;
}
.list-title{
  width: 200px;
}
.list-title.-adjust{
  padding-left: 42px;
}
.list-unit{
  text-align: left;
}
.listGroup p:last-child{
    margin-left: auto;
}
.modal-note{
  font-size: 12px;
  color: #5c5e62;
}
.action-footerWrapper{
position: fixed;
bottom: 0;
width: calc(100% - 488px);
z-index: 999;
}
.action-footer{
display: flex;
justify-content: space-between;
align-items: center;
width: 500px;
margin: auto;
background-color: #f4f4f4;
border-radius: 20px 20px 0 0;
padding: 26px 40px;
cursor: pointer;
}
.footer-value{
font-size: 14px;
}
.footer-value span{
font-size: 16px;
font-weight: 600;
letter-spacing: .025em;
}
.footer-button{
background-color: #fff;
border-radius: 16px;
padding: 5px 30px 3px;
box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 rgb(0 0 0 / 10%);
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}
.option-item {
  width: 100%;
}