.input-wrap {
    width: 100%;
    input {
        padding: 0;
        border: none;
        border-radius: 0;
        outline: none;
        background: none;
        box-sizing: border-box;
        resize: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .input-text {
        font-size: 1rem;
        font-family: 'Manrope', sans-serif;
        border-radius: .2rem;
        color: #5D5D5F;
        background-color: #fffdfd;
        border: 1px solid #444141;
        padding: .2rem;
        width: 100%;
        transition: border .25s cubic-bezier(.5,.25,.25,.75);
    }
}