.results {
    width: 100%;
    padding: 2rem;
}

.pageHead {
    padding: 2rem 1rem 0 1rem;
    text-align: right;
}
.button {
    margin-left: auto;
    background-color: #3e6ae1;
    color: #fff;
    border: none;
    border-radius: 16px;
    padding: 5px 28px;
    transition: .5s;
}


.product-list {
    width: 100%;
    padding-bottom: 2rem;
    overflow-x: auto;
}


[data-amplify-authenticator] {
    margin-top: 8rem;
    --amplify-components-button-primary-background-color: rgb(41, 26, 238);
    --amplify-components-button-primary-active-background-color: rgb(47, 19, 230);
    --amplify-components-button-primary-visited-background-color: rgb(37, 16, 224);
    --amplify-components-button-primary-focus-background-color: rgb(61, 14, 231);
    --amplify-components-button-primary-hover-background-color: rgb(113, 107, 238);
    --amplify-components-button-link-color: rgb(72, 30, 210);
    --amplify-components-button-link-hover-color: rgb(57, 30, 210);
    --amplify-components-button-link-focus-color: rgb(57, 30, 210);
    --amplify-components-button-link-active-color: rgb(193, 185, 255);
    --amplify-components-button-link-hover-background-color: rgb(191, 185, 255);
    --amplify-components-button-link-active-background-color: rgb(108, 96, 244);
  }
  .signin-title {
    font-size: 2rem;
    padding-bottom: 1rem;
    text-align: center;
  }
  
