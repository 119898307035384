table {
    table-layout: auto;
    border-collapse:  collapse;
    width: 100%;
    th,td {
        border-top: solid 1px;
        border-bottom: solid 1px;
        text-align: left;
        padding: .3rem 2rem;
    }
    th {
        white-space: nowrap;
    }
}